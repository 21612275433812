


$(function(){

// -- scroll to --------------------------------------------------------------------------------------------
	$('[data-scroll]').on('click', function(e){

		e.preventDefault();

		var $el 		=	$( $(this).data('scroll') );
		console.log($el);
		var $offset 	=	$el.offset().top ;

		$('html,body')
			.animate({
				scrollTop: ( $offset - 120 )
			}, 500, 'swing', function() {
			//	location.hash = $hash ;
		});

	});


// --  --------------------------------------------------------------------------------------------
	$('.box-inner').each( function() {
		var image = $(this).data('image');
		$(this).css('background-image', 'url('+image+')');
	});


	$('.alert').slideDown().delay(5000).slideUp();


	$('[data-lightbox]').lightcase({
		attr: 'data-lightbox',
		maxWidth: 	1200,
		maxHeight: 	1000
	})

});